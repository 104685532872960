<div *ngIf="checkedTags.length > 0" class="selectedFilters">
    <h4>Seçimleriniz</h4> <sup style="color:darkcyan;" *ngIf="checkedTags.length > 0"
        (click)="cleanFilter('', true)">Tümünü temizle</sup>
    <div class="filterList">
        <ul *ngFor="let tag of checkedTags">
            <li *ngFor="let filterValue  of tag.values"> {{tag.Name}}: {{filterValue}}<span class="close"
                    (click)="deleteFilter(tag.Name, filterValue)">x</span></li>
        </ul>
    </div>
</div>

<div class="collection-collapse-block pt-0 open" *ngFor="let item of attributes" style="margin-top:20px">
    <h3 class="collapse-block-title" *ngIf="item.Name != 'CİNSİYET'">{{item.Name}} <sup style="color:darkcyan;" *ngIf="isSelected(item.Name)"
            (click)="cleanFilter(item.Name)">Temizle</sup></h3>
    <div class="collection-collapse-block-content">
        <div class="collection-brand-filter">
            <div *ngIf="item.Name != 'FİYAT'">
                <div class="custom-control custom-checkbox collection-filter-checkbox"
                    *ngFor="let filter of item['Values']">
                    <input type="checkbox" class="custom-control-input" [value]="filter.Value"
                        (change)="changeSearchFilter($event, item.Name)" #item.Name id="{{filter.Value}}">
                    <label class="custom-control-label" for="{{filter.Value}}">{{filter.Value}}</label>
                </div>
            </div>
            <div *ngIf="item.Name == 'FİYAT'">
                <div class="mt-5">
                    <form [formGroup]="sliderForm">
                        <ng5-slider *ngIf="options" formControlName="sliderControl" [options]="options"
                            (userChangeEnd)="onUserChange()"></ng5-slider>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>