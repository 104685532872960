import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/shared/service/customer-service';
import { DomSanitizer } from '@angular/platform-browser';
import { SettingsService } from 'src/app/shared/service/settings.service';

@Component({
  selector: 'app-ecommerce-footer',
  templateUrl: './ecommerce-footer.component.html',
  styleUrls: ['./ecommerce-footer.component.scss']
})
export class EcommerceFooterComponent implements OnInit {
  settings = {};
  whatsapp = '';
  company;
  theme;
  letters = [
    {
      old: "İ",
      to: "i"
    },
    {
      old: "I",
      to: "ı"
    },
    {
      old: "Ş",
      to: "ş"
    },
    {
      old: "Ç",
      to: "ç"
    },
    {
      old: "Ğ",
      to: "ğ"
    },
    {
      old: "Ö",
      to: "ö"
    },
    {
      old: "Ü",
      to: "ü"
    }
  ]
  constructor(
    public customerService: CustomerService,
    private sanitizer: DomSanitizer,
    private settingsService: SettingsService
  ) {
    this.company = settingsService.companyInfos;
    this.theme = settingsService.theme;
  }

  ngOnInit() {
    this.customerService.list().subscribe(res => { 
    });
    console.log('qe' , this.customerService.csList)
    this.settings = JSON.parse(localStorage.getItem('settings')) || {};
    this.whatsapp = `whatsapp://send?phone=${this.settings['whatsapptel']}&abid=${this.settings['whatsapptel']}`;
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  des(text){
    text.toUpperCase()[0] = 'P';
  }


  convertString(phrase) {
  
    var newUrl = phrase.toLowerCase();
    
    newUrl = newUrl.replace(/ö/g, 'o');
    newUrl = newUrl.replace(/ç/g, 'c');
    newUrl = newUrl.replace(/ş/g, 's');
    newUrl = newUrl.replace(/ğ/g, 'g');
    newUrl = newUrl.replace(/ü/g, 'u');  
    newUrl = newUrl.replace(/ı/g, 'i');  

    newUrl = newUrl.replace(/[^a-z0-9\s-]/g, "");
    newUrl
    newUrl = newUrl.replace(/[\s-]+/g, " ");

    newUrl = newUrl.replace(/^\s+|\s+$/g,"");


    newUrl = newUrl.replace(/\s/g, "-");  

    return newUrl;
    
  }
  

      fistTurkUpper(text) {
        this.letters.forEach(letter => {
          if(text[0] == letter.to) {
            text = text.replace(text[0], String(letter.old))
          }
          else {
            text = text.replace(text[0], String(text[0].toUpperCase()))
          }
        })
        return text;
      }
    
      turkLower(text) {
        for(let i = 0; i < text.length; i++) {
          this.letters.forEach(letter => {
            if(text[i] == letter.old) {
              text = text.replace(text[i], String(letter.to))
            }
          })
        }
        text = text.toLowerCase();
        return this.fistTurkUpper(text);
      }

}
