<section class="ecommerce pt-0 ecommerce-home">
    <owl-carousel-o [options]="ecombannerOptions" class="ecommerce-home-slider">
        <ng-template carouselSlide *ngFor="let banner of bannerList">
            <div class="item-class" [ngStyle]="{'background-image': 'url('+ banner.ResimUrl +')'}" (click)="navigateBanner(banner)">
                <!-- <div class="container">
                    <div class="row">
                        <div class="col-xl-5 col-lg-6 col-sm-8">
                            <div class="contain">
                                <div>
                                    <h1>{{data.title}}</h1>
                                    <p>{{data.description}}</p>
                                    <a [routerLink]="data.link" class="btn btn-default primary-btn  radius-0" href="javascript:void()">{{data.btn}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </ng-template>
    </owl-carousel-o>
    <div class="container">
        <div class="row banner-three">
            <div class="col-md-4" *ngFor="let collection of collectionList">
                <div class="main-wrapper mouse shadow  b-rad" [routerLink]="['/collection/' + collection.HandleUrl]">
                    <img alt="#" class="img-h bg-img img-fluid b-rad" [src]="collection.ResimUrl">
                </div>
            </div>
        </div>
    </div>
</section>