import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  companyInfos = {
    name: 'Efes Tarım',
    contact: {
      address: '75. Yıl Cumhuriyet Mah., İzmir – Ankara Caddesi No:12/1, 35730,Kemalpaşa,İzmir',
      phones: [
        {
          title: 'Telefon ',
          no: '+90 (232) 880 60 18-19-20'
        },
        // {
        //   title: 'Telefon ',
        //   no: '0 555 800 11 89'
        // }
      ],
      fullName: 'Efes Tarım Ürünleri Hayvancılık Gıda Sanayi ve Ticaret A.Ş.',      
      fax: '+90 (232) 880 60 21',
      email: 'info@efestarim.com.tr'
    },
    social: [
      {
        icon: 'fa fa-instagram',
        link: ''
      },
      {
        icon: 'fa fa-facebook-square',
        link : ''
      }
    ]
  }
  theme = {
    type: 'shop', // shop - gallery
    title: 'Efes Tarım',
    logo: {
      selected: 'image', // text - image
      text: 'Efes Tarım',
      headerLogo: 'assets/images/logo/logo2.png',
      footerLogo: 'assets/images/logo/logo2.png'
    },
    favicon: 'assets/images/logo/mislinahome-logo.jpg',
    maintenance: {
      active: false,
      leftImage:  'assets/images/logo/logo2.png'
    },
    showNoStock: 1,
    learnshow:0

  }

  constructor() { }

}
