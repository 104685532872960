<div class="agency">
    <app-ecommerce-header></app-ecommerce-header>
    <!-- breadcrumb section start -->
    <section class="breadcrumb-section-main inner-2 breadcrumb-section-sm border-bottom border-top">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb-contain ">
                        <div>
                            <ul>
                                <li><a [routerLink]="''"><i class="fa fa-home" aria-hidden="true"></i></a></li>
                                <li><a href="javascript:void()"><i class="fa fa-angle-double-right"></i></a></li>
                                <li><a href="javascript:void()">Alışveriş</a></li>
                                <li><a href="javascript:void()"><i class="fa fa-angle-double-right"></i>Sepetim</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--breadcrumb section end -->

    <!-- section start -->
    <section class="cart-section section-b-space">
        <div class="container">
            <div class="col-sm-12 empty-cart-cls text-center" *ngIf="!shoppingCartItems.length">
                <img src="assets/images/icon-empty-cart.png" class="img-fluid mb-4">
                <h3><strong>Sepetiniz boş</strong></h3>
                <h4>Hadi birşeyler ekleyin :)</h4>
                <a [routerLink]="['']" class="btn btn-default primary-btn radius-0 m-t-10">ALIŞVERİŞE DEVAM ET</a>
            </div>
            <div class="row" *ngIf='shoppingCartItems.length'>
                <div class="col-sm-12">
                    <table class="table cart-table table-responsive-xs">
                        <thead>
                            <tr class="table-head">
                                <th scope="col">RESİM</th>
                                <th scope="col">ÜRÜN ADI</th>
                                <th scope="col">FİYAT</th>
                                <th scope="col">MİKTAR</th>
                                <th scope="col"></th>
                                <th scope="col">TOPLAM</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of shoppingCartItems">
                            <tr>
                                <td>
                                    <div class="mobile-delete">
                                        <a [routerLink]="" (click)="removeItem(item)" class="icon d-block d-sm-none">
                                            <i class="fa fa-times"></i>
                                        </a>
                                        <a [routerLink]="['/urun-detay', item?.ProductId]">
                                            <img [src]="item?.ResimUrl" alt="">
                                        </a>
                                    </div>
                                </td>
                                <td>
                                    <a [routerLink]="['/urun-detay', item?.ProductId]">{{ item?.ProductName }}</a>
                                    <div class="mobile-cart-content row">
                                        <div class="col-xs-3">
                                            <div class="qty-box">
                                                <div class="input-group">
                                                    <input type="text" name="quantity" class="form-control input-number" disabled [value]="item?.Quantity">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-3">
                                            <h2 class="td-color">{{ item?.SalePrice }} TL</h2>
                                        </div>
                                        <div class="col-xs-3">
                                            <h2 class="td-color">
                                                <a (click)="removeItem(item)" class="icon">
                                                    <i class="ti-close"></i>
                                                </a>
                                            </h2>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <h2>{{ item?.SalePrice }} TL</h2>
                                </td>
                                <td>
                                    <div class="qty-box">
                                        <div class="input-group">
                                            <span class="input-group-prepend">
                                              <button type="button" class="btn quantity-left-minus" (click)="decrement(item)" data-type="minus" data-field="">
                                                <i class="fa fa-chevron-left"></i>
                                              </button>
                                            </span>
                                            <input type="text" name="quantity" disabled class="form-control input-number" [(ngModel)]="item.Quantity">
                                            <span class="input-group-prepend">
                                              <button type="button" class="btn quantity-right-plus" (click)="increment(item)" data-type="plus" data-field="">
                                                <i class="fa fa-chevron-right"></i>
                                              </button>
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <a [routerLink]="" (click)="removeItem(item)" class="icon">
                                        <i class="fa fa-times"></i>
                                    </a>
                                </td>
                                <td>
                                    <h2 class="td-color">{{ item?.DiscountAmount }} TL</h2>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table cart-table table-responsive-md">
                        <tfoot style="width: 100%;">
                            <tr>
                                <td style="width:50%">TOPLAM :</td>
                                <td style="width:50%">
                                    <h2> {{ getSubTotal }} TL</h2>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div class="row cart-buttons" *ngIf='shoppingCartItems.length'>
                <div class="col-md-6" style="text-align: center;">
                    <a [routerLink]="['']" class="btn btn-default primary-btn radius-0 m-t-10">ALIŞVERİŞE DEVAM ET</a>
                </div>
                <div class="col-md-6" style="text-align: center;">
                    <a [routerLink]="['/odeme']" class="btn btn-default primary-btn ra-dius-0 mt-10">ÖDEME ADIMINA GEÇ</a>
                </div>
            </div>
        </div>
    </section>
    <!--section end-->
    <app-ecommerce-footer></app-ecommerce-footer>
</div>