import { Component, OnInit } from '@angular/core';
import { CollectionService } from '../../../service/collection.service';
import { ActivatedRoute } from '@angular/router';
import { FilterService } from 'src/app/shared/service/filter.service';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { element } from 'protractor';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss']
})
export class CollectionComponent implements OnInit {
  collections;
  collections2 = [];
  collections3 = [];
  constructor(
    public filterService:FilterService,
    public collectionService: CollectionService
    ) { 
      this.collections = [];
    }
  
  // collapse toggle
  ngOnInit() { 
    this.collectionService.collectionListDetector.subscribe(res => {

    
      // if(res) {
      //   if (res.Children && res.Children.length > 0) {
      //     this.collections = res.Children;
      //     this.collectionService.collectionChangeDetector.next(res);
      //   }else {
          // this.collectionService.collectionChangeDetector.next(res);
      //   }
      // }else {
      //   this.getCollections();
      // }
    }) 
     this.getCollections();
     console.log('co2' , this.collections2)
  }

  // For mobile view
  public mobileFilterBack() {  }
 
  toggleFilter(){
    this.filterService.filterBar = !this.filterService.filterBar
  }

  getCollections() {
    this.collectionService.listTree().subscribe(res => {
      this.collections = res;

      this.collections.forEach(element => {
        this.collections3 = element
       
      })

      

          
  
     
      res.forEach(element => {
          if(element.Item.GosterimYeri != '1'){
            this.collections2.push(element);
          }
      });

      this.collections3['Children'].forEach(r => {
        this.collections3 = r;

        if(this.collections3['Children']){
         this.collections3['Children'].forEach(qe => {
           this.collections2.push(qe)
         })
         
      }
       
    
        if(this.collections3['Item'].GosterimYeri == null){
           this.collections2.push(this.collections3)
           
        }
     })

     


    });
  }

  changeCollection(collection)  {
    this.collectionService.collectionChangeDetector.next(collection.Item);
  }
   
}
