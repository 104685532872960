<footer class="ecommerce-footer" id="footer">
    <div class="container">
        <div class="row footer-theme partition-f">
            <div class="col-lg-4 p-0 col-md-12">
                <div class="footer-contant">
                    <div class="footer-logo"><a href="./" class="logo samsung-font-bold"><img src="assets/images/logo/logo2.png" alt=""></a></div>
                    <div class="footer-social">
                        <ul>
                            <li><a href="javascript:void()"><i aria-hidden="true" class="fa fa-facebook"></i></a></li>
                            <!-- <li><a href="javascript:void()"><i aria-hidden="true" class="fa fa-google-plus"></i></a></li> -->
                            <!-- <li><a href="javascript:void()"><i aria-hidden="true" class="fa fa-twitter"></i></a></li> -->
                            <li><a href="javascript:void()"><i aria-hidden="true" class="fa fa-instagram"></i></a></li>
                            <!-- <li><a href="javascript:void()"><i aria-hidden="true" class="fa fa-rss"></i></a></li> -->
                        </ul>
                    </div>
                    <div class="mobile-app-links">
                        <!-- <h4>
                            Mobil Uygulama Linklerimiz:
                        </h4>
                        <div class="row">
                            <div class="col-6">
                                <a href="https://apps.apple.com/us/app/kurumsal-reyon/id1499496462?l=tr&ls=1" target="_blank">
                                    <img src="assets/images/appstore.png" alt="Kurumsal Reyon AppStore">
                                </a>
                            </div>
                            <div class="col-6">
                                <a href="https://play.google.com/store/apps/details?id=com.glopark.destech" target="_blank">
                                    <img src="assets/images/googleplay.png" alt="Kurumsal Reyon Google Play">
                                </a>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col p-0 offset-xl-1">
                <div class="sub-title">
                    <div class="footer-title">
                        <h4>Hesabım</h4>
                    </div>
                    <div class="footer-contant">
                        <ul>
                            <li><a href="javascript:void()" [routerLink]="['/hesabim']">Bilgilerim</a></li>
                            <li><a href="javascript:void()" [routerLink]="['/siparislerim']">Siparişlerim</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col p-0">
                <div class="sub-title">
                    <div class="footer-title">
                        <h4>Müşteri Hizmetleri</h4>
                    </div>
                    <div class="footer-contant">
                        <ul>
                            <li *ngFor="let cs of customerService.csList" ><a [routerLink]="['/pages', convertString(cs.Baslik)]">{{cs.Baslik | titlecase}}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col p-0">
                <div class="sub-title">
                    <div class="footer-title">
                        <h4>Mağaza İletişim</h4>
                    </div>
                    <div class="footer-contant">
                        <ul class="contact-list">
                            <li class="pl-0 word-breaks text-dark">Efes Tarım Ürünleri Hayvancılık Gıda Sanayi ve Ticaret A.Ş.</li>
                            <li class="word-breaks text-dark"><i class="fa fa-map-marker"></i>75. Yıl Cumhuriyet Mah., İzmir – Ankara Caddesi No:12/1, 35730,Kemalpaşa,İzmir</li>
                            <li class="word-breaks text-dark"><i class="fa fa-phone"></i>İletişim: <a href="tel:02328806018">0232 880 60 18</a></li>
                            <li class="word-breaks text-dark"><i class="fa fa-envelope-o"></i> <a href="mailto:info@efestarim.com.tr">E-Posta: info@efestarim.com.tr</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="sub-footer">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="footer-end row">
                        <p class="pt-0"><small><i aria-hidden="true" class="fa fa-copyright"></i> Copyright 2020 <a href="https://www.glopark.com/" target="_blank" class="copyright-link"><small>Glopark</small></a> | Tüm Hakları Saklıdır. Kredi kartı bilgileriniz 256bit
                            SSL sertifikası ile korunmaktadır.</small>
                        </p>
                        <div class="card-brands d-flex">
                            <img src="assets/images/icon/trushsafe-logo.jpg" alt="master">
                            <img src="assets/images/icon/master-logo.png" alt="master">
                            <img src="assets/images/icon/visa-logo.png" alt="master">
                            <img src="assets/images/icon/troy-logo.png" alt="master">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<app-tap-to-top></app-tap-to-top>
<a [href]="sanitize(whatsapp)" class="whatsapp-btn">
    <i aria-hidden="true" class="fa fa-whatsapp"></i>
</a>