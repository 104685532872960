<div class="agency">
    <app-ecommerce-header></app-ecommerce-header>
    <!-- breadcrumb section start -->
    <section class="breadcrumb-section-main inner-2 breadcrumb-section-sm border-top border-bottom">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb-contain ">
                        <div>
                            <ul>
                                <li><a [routerLink]="''"><i class="fa fa-home" aria-hidden="true"></i></a></li>
                                <li><a href="javascript:void()"><i class="fa fa-angle-double-right"></i>{{product?.title}}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--breadcrumb section end -->
    <!-- section start -->
    <div class="agency">
        <section>
            <div class="collection-wrapper">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-sm-12 col-xs-12">
                            <div class="container-fluid">
                                <div class="col-lg-9 col-sm-12 col-xs-12">
                                    <div class="row">
                                        <div class="col-lg-6" *ngIf="product">
                                            <owl-carousel-o [options]="productSliderOptions" #owlCar class="product-slick">
                                                <ng-template carouselSlide [id]="i" *ngFor="let data of product.image; index as i">
                                                    <img alt="" class="img-fluid" [src]="data.url">
                                                </ng-template>
                                            </owl-carousel-o>
    
                                            <div class="col-12 p-0">
                                                <div class="slider-nav">
                                                    <owl-carousel-o [options]="productSliderOptions1" class="product-slick">
                                                        <ng-template carouselSlide [id]="i" *ngFor="let data of product.image; index as i">
                                                            <img alt="" class="img-fluid" [src]="data.url" (click)="owlCar.to(i.toString())">
                                                        </ng-template>
                                                    </owl-carousel-o>
                                                </div>
                                            </div>
                                        </div>
    
                                        <div class="col-lg-6 rtl-text" *ngIf="product">
                                            <div class="product-right">
                                                <h2>{{product.title}}</h2>
                                                <h4 *ngIf="learnshow == 0">
                                                    <del *ngIf="product.displayPrice > product.salePrice">{{product.displayPrice}} TL</del>
                                                    <!-- <span>{{product.salePrice}} TL</span> -->
                                                </h4>
                                                <h3 *ngIf="learnshow == 0">{{product.salePrice}} TL</h3>
                                                <div *ngIf="learnshow == 0" class="product-description border-product">
                                                    <div class="size-box" *ngFor="let patt of productAttributes">
                                                        {{patt.name}}
                                                        <ul>
                                                            <li [ngClass]="{'active': selectedAttributes[patt.name] == paV.value}" *ngFor="let paV of patt.values">
                                                                <a (click)="changeVariantSize(patt, paV)">{{paV.value}}</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="border-product" *ngIf="productAttributes.length > 0"></div>
                                                    <h5 class="avalibility" *ngIf="maxAmount != 0"><span>Stoklarda</span></h5>
                                                    <h5 class="avalibility text-danger" *ngIf="maxAmount == 0"><span>Stokta yok</span></h5>
                                                    <!-- <h6 class="product-title">Stok Adedi</h6> -->
                                                    <div class="qty-box" *ngIf="maxAmount != 0">
                                                        <div class="input-group">
                                                            <span class="input-group-prepend">
                                                                <button type="button" class="btn quantity-left-minus" (click)="decrement()"
                                                                    data-type="minus" data-field="">
                                                                    <i class="fa fa-chevron-left"></i>
                                                                </button>
                                                            </span>
                                                            <input type="text" name="quantity" disabled class="form-control input-number" value="{{counter}}">
                                                            <span class="input-group-prepend">
                                                                <button type="button" class="btn quantity-right-plus" (click)="increment()"
                                                                    data-type="plus" data-field="">
                                                                    <i class="fa fa-chevron-right"></i>
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="product-buttons" *ngIf="(maxAmount != 0) && learnshow == 0">
                                                    <a [routerLink]="" (click)="addToCart()" class="btn btn-default primary-btn radius-0" [class.disabled]="false">Sepete Ekle</a>
                                                    <a [routerLink]="" (click)="buy()" class="btn btn-default primary-btn radius-0">Hemen Al</a>
                                                </div>
                                                <a (click)="requestPrice()" routerLinkActive="router-link-active" *ngIf="learnshow == 1">
                                                    <button class="btn btn-default primary-btn radius-0 w-100">
                                                        Fiyat İste
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Size Modal -->
                            <ng-template #content let-modal>
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Sheer Straight Kurta
                                    </h5>
                                    <button aria-label="Close" class="close" (click)="modal.close('Save click')" type="button">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <img alt="" class="img-fluid" src="assets/images/inner-page/product/size-chart.jpg">
                                </div>
                            </ng-template>
                            <section class="tab-product m-0 p-b-0" *ngIf="product">
                                <div class="row">
                                    <div class="col-sm-12 col-lg-12">
                                        <ngb-tabset>
                                            <ngb-tab title="Ürün Detay">
                                                <ng-template ngbTabContent>
                                                    <p class="p-b-0" [innerHtml]="product.description"></p>
                                                </ng-template>
                                            </ngb-tab>
                                        </ngb-tabset>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <!-- Section ends -->
    <app-ecommerce-footer></app-ecommerce-footer>
</div>